// 手机中需要暂时保存状态的模块
const mobile = {
  state: {
    stuList:[], // 学生列表
    page:0,  // 当前在的页码
    scrollTop: 0, // 学生列表页滚动条位置
    curStu:JSON.parse(sessionStorage.getItem('curStu')) || {}, // 当前选中的学生
    curTea:JSON.parse(sessionStorage.getItem('curTea')), // 当前选中的教师
  },
  mutations: {
    SET_STULIST(state,pageStuList){
      state.stuList = state.stuList.concat(pageStuList.stuList);
      state.page = pageStuList.page;
    },
    SET_SCORLL_TOP(state,scrollTop){
      state.scrollTop = scrollTop;
    },
    SET_CURSTU(state,curStu){
      sessionStorage.setItem('curStu',JSON.stringify(curStu));
      state.curStu = curStu;
    },
    // 清空已上数据
    CLEAR_DATA(state){
      state.stuList = [];
      state.page = 0;
      state.scrollTop = 0;
      state.curStu = {};
      state.curTea = {};
    },
    // 修改学生后修改学生列表
    UPDATE_STULIST(state,stu){
      state.stuList=state.stuList.map(item=>{
        if(item.sid===stu.sid)item=stu;
        return item;
      })
    },
    // 设置当前选中的教师id
    SET_TEA(state,curTea){
      state.curTea = curTea;
      sessionStorage.setItem('curTea',JSON.stringify(curTea));
    }
  }
};
export default mobile;

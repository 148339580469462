import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import $ from "jquery";

// 配置请求的基础路径,
// 需要配置四个请求路径axios UpdatePwd store中的ws 服务器端上传接口中图片路径
// 百度云"http://180.76.233.100:8008",
// 本地"http://127.0.0.1:8008",
const baseURL = "://180.76.233.100:8008";
Vue.prototype.baseURL = baseURL;

import axios from "axios";
axios.defaults.baseURL = "http" + baseURL;
//将axios放入的VUE原型对象中
Vue.prototype.$axios = axios;

//配置moment
import moment from "moment";
moment.locale("my-settings", {
  parentLocale: "zh-cn",
  meridiem: function (hour, minute, isLower) {
    const hm = hour * 100 + minute;
    if (hm < 1159) {
      return "上午";
    } else if (hm < 1659) {
      return "下午";
    } else {
      return "晚上";
    }
  },
});
Vue.prototype.$moment = moment;

// element组件库 与自定义公共样式
import "element-ui/lib/theme-chalk/index.css";
import "./styles/theme/index.css";
import ElementUI from "element-ui";
Vue.use(ElementUI);
//重写Message方法,将offset设置默认值为200
import { Message } from "element-ui";
const $message = (options) => {
  return Message({
    ...options,
    offset: 70,
  });
};

["success", "warning", "info", "error"].forEach((type) => {
  $message[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: options,
        offset: 70,
      };
    }
    options.type = type;
    return Message(options);
  };
});
Vue.prototype.$message = $message;

// 引入vant组件
import {
  NavBar,
  Tabbar,
  TabbarItem,
  Form,
  Field,
  CellGroup,
  Button,
  Image as VanImage,
  Checkbox,
  CheckboxGroup,
  Icon,
  Collapse,
  CollapseItem,
  Search,
  Cell,
  Sticky,
  Swipe,
  SwipeItem,
  Popup,
  Grid,
  GridItem,
  Divider,
  RadioGroup,
  Radio,
  DatetimePicker,
  List,
  Tag,
  SwipeCell,
  Empty,
  Step,
  Steps,
  Picker,
  Toast,
  Dialog,
} from "vant";
import "vant/lib/index.less";
import "./styles/mycommon.css"; //引入公共样式
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Sticky);
Vue.use(Search);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Picker);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Empty);
Vue.use(SwipeCell);
Vue.use(Tag);
Vue.use(List);
Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Divider);
Vue.use(Popup);
Vue.use(DatetimePicker);

// 判断是否为移动端
function ISMOBILE() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}
Vue.prototype.isMOBILE = ISMOBILE();

// 配置jQuery
Vue.prototype.$ = $;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 数据表改造
/* 
一共九张表:banke_list,class_list,grade_list,price_list,msg_list,salary_list,stu_list,tea_list,xutui_record
1. 导出boyu数据库
2. 删除history_salary、message_list、root_list、删除外键
3. 导入数据库
4. 删除学生列表中的ssex字段，修改报名课时字段和赠送课时字段为bmks、zsks，修改monitor字段默认值为32，onetwo字段默认值为0;
5. 学生列表中班课的monitor改成32 执行  UPDATE `stu_list` SET `monitor`=32 WHERE `session`<1000
6. 删除教师列表中的9~22个字段，第7个字段后加一个	authority 字段，给郑帅朋权限4，给包炜岗权限0，给班主任权限1,5  
  执行 UPDATE `tea_list` SET `authority`='1,5' WHERE `job`=1
*/

<template>
  <div id="app">
    <my-header v-if="!isMOBILE&&user"></my-header>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MyHeader from '@/components/PC/MyHeader.vue';
export default {
  components: { MyHeader },
  computed:{
    ...mapState(["user"]),
    ...mapGetters(["isAllTea","isRoot"])
  },
  methods:{
    ...mapActions(["getTeaList","conWebSocket","getMessageList"]),
  },
  created(){
    if(this.user){
      this.getTeaList(this.isAllTea);
      this.conWebSocket();
      if(!this.isRoot){
        this.getMessageList();
      }
    }
  }
}
</script>
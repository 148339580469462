const routerM=[
    {path:'/home', url: 'HomeView'},
    {path:'/me', url: 'MeView'},
    {path:'/message', url: 'MessageView'},
    {path:'/updatePwd', url: 'UpdatePwd'},
    {path:'/teaclass', url: 'TeaClass'},
    {path:'/stulist', url: 'StuList'},
    {path:'/banlist', url: 'BanList'},
    {path:'/studetail', url: 'StuDetail'},
    {path:'/bandetail', url: 'BanDetail'},
    {path:'/updateStu', url: 'UpdateStu'},
    {path:'/updateBan', url: 'UpdateBan'},
    {path:'/addStu', url: 'AddStu'},
    {path:'/xutui/:type', url: 'XuTui'},
    {path:'/addBan', url: 'AddBan'},
    {path:'/tealist', url: 'TeaList'},
    {path:'/addTea', url: 'AddTea'},
    {path:'/teaClassRecord', url: 'TeaClassRecord'},
    {path:'/updateTea', url: 'UpdateTea'},
    {path:'/stuclass/:stuId', url: 'StuClass'},
    {path:'/jiabanwanfu', url: 'JiabanWanfu'},
    {path:'/heiduikeshi', url: 'HeiduiKeshi'},
    {path:'/authorityManage', url: 'AuthorityManage'},
]

export default routerM
import Vue from 'vue'
import VueRouter from 'vue-router'
import routerM from './routerM';
import routerPC from './routerPC';
import LoginViewM from '../views/mobile/LoginView.vue'
import LoginViewP from '../views/PC/LoginView.vue'
import { isMOBILE } from "@/xutil/index";

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
  return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
  return originalReplace.call(this , location).catch(err=>err)
}

Vue.use(VueRouter)

const routersPC = [
  {
    path: '/',
    name: 'login',
    component: LoginViewP
  }
]

routerPC.forEach(item=>{
  let name = item.path.split('/')[1];
  let obj = {
    path: item.path,
    name,
    component: () => import(/* webpackChunkName: "home" */ '../views/PC/' + item.url + '.vue')
  }
  routersPC.push(obj);
})

const routesM = [
  {
    path: '/',
    name: 'login',
    component: LoginViewM
  }
]

routerM.forEach(item=>{
  let name = item.path.split('/')[1];
  let obj = {
    path: item.path,
    name,
    component: () => import(/* webpackChunkName: "home" */ '../views/mobile/' + item.url + '.vue')
  }
  routesM.push(obj);
})

// 创建路由实例
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  fallback: false,
  // 这里判断后选择使用移动端的路由还是pc端的路由
  routes: isMOBILE ? routesM : routersPC,
});

//路由守卫
router.beforeEach((to, from, next)=>{
  let user=JSON.parse(sessionStorage.getItem('user'))
  if(user && (to.path=='/')){
    next('/home')
  }else if(!user && to.path!=='/'){
    next('/');
  }else{
    next();
  }
})

export default router

import html2canvas from "html2canvas";
import moment from "moment";

// 判断是否为移动端
function ISMOBILE() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}
const isMOBILE = ISMOBILE();
// 构造函数 教师课表 核对课时时使用
function TeaClassList(params) {
  if (params) {
    this.tid = params.teaId;
    this.tname = params.tname;
    delete params.teaId;
    delete params.stuId;
    delete params.tname;
    delete params.monitor;
    delete params.sid;
    delete params.subject;
    this.cladata = [params];
  } else {
    this.tid = 0;
    this.tname = "";
    this.cladata = [];
  }
}
TeaClassList.prototype.getBanNum = function () {
  return this.cladata.reduce((total, cla) => {
    return cla.session < 1000 ? ++total : total;
  }, 0);
};
TeaClassList.prototype.getDuiNum = function () {
  return this.cladata.reduce((total, cla) => {
    return cla.session > 1000 ? ++total : total;
  }, 0);
};
TeaClassList.prototype.getLength = function () {
  return this.cladata.reduce((total, cla) => {
    if (cla.session > 1000) {
      return total + cla.classLength;
    } else {
      return total;
    }
  }, 0);
};
//处理教师课表的固定方法，课时核对中使用
function zhuliTeaClassList(list) {
  if (list.length > 0) {
    let teaArr = [];
    list.forEach((cla) => {
      let tea = teaArr.find((tea) => tea.tid == cla.teaId);
      if (tea) {
        delete cla.teaId;
        delete cla.stuId;
        delete cla.tname;
        delete cla.monitor;
        delete cla.sid;
        delete cla.subject;
        tea.cladata.push(cla);
      } else {
        teaArr.push(new TeaClassList(cla));
      }
    });
    teaArr.sort((a, b) => b.cladata.length - a.cladata.length);
    return teaArr;
  } else {
    return null;
  }
}
// 处理学生课表的固定方法，发课表中使用
function chuliStuClassList(list) {
  let stuArr = [];
  list.forEach((cla) => {
    if (stuArr.some((stu) => stu.sid == cla.sid)) {
      let stu = stuArr.filter((stu) => stu.sid == cla.sid)[0];
      stu.cladata.push(cla);
    } else {
      let stu = {
        sid: cla.sid,
        sname: cla.sname,
        session: cla.session,
        monitor: cla.monitor,
        cladata: [],
        isCopy: false,
      };
      stu.cladata.push(cla);
      stuArr.push(stu);
    }
  });
  stuArr.sort((a, b) => b.session - a.session);
  return stuArr;
}
//对象转字符串  obj==>key1=value1&key2=value2
function postJson(object) {
  let arr = [];
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const element = object[key];
      arr.push(`${key}=${element}`);
    }
  }
  return arr.join("&");
}
// 构造函数 空课时对象
function EmtryCla(params) {
  if (params) {
    let snamegrade = params.sname;
    if (params.session > 1000) {
      let i =
        moment(params.datetime).year() -
        params.session +
        params.gradeArr.length;
      if (moment(params.datetime).month() < 8) i--;
      snamegrade += isMOBILE ? "<br/>(" : "(";
      snamegrade += params.gradeArr[i] + ")";
    }
    this.datetime = params.datetime;
    this.session = params.session;
    this.sname = params.sname;
    this.snamegrade = snamegrade;
    this.cid = params.cid;
    this.stuId = params.stuId;
    this.monitor = params.monitor;
    this.sub = params.sub;
    this.teaId = params.teaId;
  } else {
    this.datetime = "";
    this.session = 1000;
    this.sname = "";
    this.snamegrade = "-";
    this.cid = 0;
    this.stuId = 0;
    this.monitor = 0;
    this.sub = "";
    this.teaId = 0;
  }
}

const authorityArr = [
  { id: "0", label: "排课权限" },
  { id: "1", label: "学生列表权限" },
  { id: "2", label: "教师列表权限" },
  { id: "3", label: "班课列表权限" },
  { id: "4", label: "权限管理权限" },
  { id: "5", label: "发课表权限" },
  { id: "6", label: "添加学生权限" },
  { id: "7", label: "添加教师权限" },
  { id: "8", label: "添加班课权限" },
  { id: "9", label: "核对课时权限" },
  { id: "J", label: "加班晚辅权限" },
  { id: "Q", label: "数据图表权限" },
  { id: "K", label: "续费退费权限" },
];

/**
 * 加法运算，避免数据相加小数点后产生多位数和计算精度损失。
 *
 * @param num1加数1 | num2加数2
 */
function numAdd(num1, num2) {
  var baseNum, baseNum1, baseNum2;
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  return (num1 * baseNum + num2 * baseNum) / baseNum;
}
/**
 * 减法运算，避免数据相减小数点后产生多位数和计算精度损失。
 *
 * @param num1被减数  |  num2减数
 */
function numSub(num1, num2) {
  var baseNum, baseNum1, baseNum2;
  var precision; // 精度
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  precision = baseNum1 >= baseNum2 ? baseNum1 : baseNum2;
  return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
}
/**
 * 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
 *
 * @param num1被乘数 | num2乘数
 */
function numMulti(num1, num2) {
  var baseNum = 0;
  try {
    baseNum += num1.toString().split(".")[1].length;
  } catch (e) {}
  try {
    baseNum += num2.toString().split(".")[1].length;
  } catch (e) {}
  return (
    (Number(num1.toString().replace(".", "")) *
      Number(num2.toString().replace(".", ""))) /
    Math.pow(10, baseNum)
  );
}

/**
 * 复制图片到剪切板。
 *
 * @param ref | 复制的dom
 * @param obj | 复制的对象
 */
async function copyImg(ref,obj){
  /* try {
    const canvas = await html2canvas(ref);
    const data = await fetch(canvas.toDataURL("image/png"));
    const blob = await data.blob();
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);
    console.log("Image copied.");
    obj.isCopy = true;
  } catch (err) {
    console.error(err.name, err.message);
  }
  setTimeout(() => {
    obj.isCopy = false;
  }, 800);
  return true; */

  let canvas = await html2canvas(ref);
  // 下载图片
  let imgUrl = canvas.toDataURL("image/png");
  let aLink = document.createElement("a");
  aLink.href = imgUrl;
  let name="本周课表.png";
  if(obj.sname){
    name=obj.sname+name
  }else if(obj.this_stu){
    name=obj.this_stu.sname+name
  }else if(obj.tea){
    name=obj.tea.tname+name
  }else if(obj.tname){
    name=obj.tname+"课时费明细.png"
  }
  aLink.download = name; //导出文件名
  document.body.appendChild(aLink);
  aLink.click();
  document.body.removeChild(aLink);
  obj.isCopy = true;
  setTimeout(() => {
    obj.isCopy = false;
  }, 800);
  return true
}

export {
  isMOBILE,
  TeaClassList,
  zhuliTeaClassList,
  chuliStuClassList,
  postJson,
  EmtryCla,
  authorityArr,
  copyImg,
  numAdd,
  numSub,
  numMulti,
};

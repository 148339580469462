<template>
  <div>
    <!-- 系统消息按钮 -->
    <el-button
      type="primary"
      plain
      class="messge-btn"
      :class="{ shanshuo: isShan }"
      @click="openMessageBox"
    >
      <i class="el-icon-chat-line-round"></i>
      <br />系<br />统<br />消<br />息
    </el-button>
    <audio src="@/assets/audio/11299.mp3" id="msgAudio"></audio>
    <!-- 系统消息抽屉 -->
    <el-drawer :visible.sync="drawer" direction="rtl" append-to-body>
      <div slot="title">
        <i class="iconfont icon-laba" style="margin-right: 128px"></i>
        <span>系统消息</span>
      </div>
      <!-- 会话内容 -->
      <div class="talk text-center">
        <el-button
          type="text"
          size="mini"
          @click="getMoreMsg"
          v-if="hasMore"
          >历史消息</el-button
        >
        <span v-else>没有消息了</span>
        <!-- 消息框 -->
        <div v-for="(item, i) of messageList" :key="i">
          <!-- 时间戳 -->
          <div>
            <el-tag
              color="#F6F6F6"
              style="color: #606266; border-color: #f6f6f6"
              size="mini"
            >
              {{ $moment(item.msgTime).format("MM月DD日 HH:mm") }}
            </el-tag>
          </div>
          <!-- 头像,名字及内容 -->
          <div class="msg-item">
            <el-avatar :src="item.avatar">管</el-avatar>
            <div class="msg-content">
              <div class="msg-text">
                <router-link
                  :to="`/stuclass/${item.stuId}`"
                  v-if="item.stuId"
                  >{{ item.msgText }}</router-link
                >
                <span v-else>{{ item.msgText }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      drawer: false, // 是否打开消息抽屉
      isShan: false, // 是否闪烁
      hasMore:true,
    };
  },
  computed: {
    ...mapState(["user", "winHeight", "socket", "messageList"]),
  },
  watch: {
    $route() {
      this.drawer = false;
    },
    messageList: {
      handler(newList, oldList) {
        if(newList.some(msg=>msg.readState === 0)){
          if(this.drawer){
            this.$('#msgAudio')[0].play()
            this.toButtom()
            this.updateReadState()
          }else{
            this.isShan=true;
            this.$('#msgAudio')[0].loop=true;
            this.$('#msgAudio')[0].play()
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["getMessageList"]),
    getMoreMsg(){
      this.getMessageList({readState:1,page:this.messageList.length}).then(res=>{
        if(res){
          this.hasMore=false;
        }
      })
    },
    // 点击消息按钮
    openMessageBox() {
      this.drawer = true;
      this.isShan = false;
      this.updateReadState();
      this.toButtom();
      this.$('#msgAudio')[0].loop=false;
      this.$('#msgAudio')[0].pause();
    },
    // 消息框滚动条滑到底
    toButtom() {
      this.$nextTick(() => {
        let talk = this.$(".talk")[0];
        if (talk && talk.clientHeight < talk.scrollHeight) {
          talk.scrollTop = talk.scrollHeight - talk.clientHeight;
        }
      });
    },
    // 改已读为未读
    updateReadState(){
      let midArr=this.messageList.filter(item=>item.readState===0&&item.toId==this.user.id).map(item=>item.mid);
      if(midArr.length>0){
        let params='midArr='+JSON.stringify(midArr)
        this.$axios.post('/www6/updateReadState',params)
          .then(res=>{
            if(res.data===1){
              this.messageList.forEach(item=>{
                item.readState=1
              })
            }
          })
      }
    }
  },
  mounted() {
    // 添加监听事件  关闭网页前断开连接
    window.addEventListener("beforeunload", () => {
      this.socket && this.socket.close(3001, this.user.id);
    });
  },
};
</script>

<style scoped>
@keyframes shan {
  0% {
    color: #ffffff;
    background-color: #e1251b;
  }
  49% {
    color: #e1251b;
    background-color: #ffffff;
  }
  51% {
    color: #e1251b;
    background-color: #ffffff;
  }
  100% {
    color: #ffffff;
    background-color: #e1251b;
  }
}
/* 打开抽屉的按钮 */
.messge-btn.el-button {
  position: fixed;
  bottom: 40px;
  right: 0;
  font-size: 18px;
  padding: 12px 8px;
  line-height: 24px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 7;
}
.shanshuo.el-button {
  animation: shan 800ms infinite;
}
/* 会话内容 */
.talk {
  overflow: auto;
  height: 100%;
}
.talk > span {
  font-size: 12px;
  color: #909399;
  display: inline-block;
  padding: 7px 0;
}
.talk::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
}
.talk::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 3px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
}
.talk::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.msg-item {
  display: flex;
  margin-top: 8px;
}
.msg-item > .el-avatar {
  background-color: #007bff;
  margin: 0 16px;
  width: 40px;
}
.msg-item > .msg-content {
  text-align: left;
  max-width: calc(100% - 119px);
  margin-bottom: 16px;
}
.msg-item > .msg-content > .msg-text {
  font-size: 14px;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  padding: 2px 8px;
}
.msg-content > .msg-text > a {
  color: #303133;
}
.msg-content > .msg-text > a:hover {
  color: #e1251b;
}
.msg-content > .msg-text > a:active {
  color: #e1251b;
}
/* 消息输入框 */
.msg-textarea {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 122px);
}
</style>

<style>
.el-drawer__header {
  color: #ffffff !important;
  margin-bottom: 0 !important;
  padding: 18px 20px 12px !important;
  background-color: #e1251b !important;
}
</style>

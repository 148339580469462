const routerPC=[
    {path:'/home', url: 'HomeView'},
    {path:'/teaclass', url: 'TeaClass'},
    {path:'/stulist/:sid', url: 'StuList'},
    {path:'/tealist/:tid', url: 'TeaList'},
    {path:'/addTea', url: 'AddTea'},
    {path:'/banlist/:id', url: 'BankeList'},
    {path:'/addBan', url: 'AddBan'},
    {path:'/addStu', url: 'AddStu'},
    {path:'/updatePwd', url: 'UpdatePwd'},
    {path:'/echarts', url: 'ECharts'},
    {path:'/heiduikeshi', url: 'KeshiHedui'},
    {path:'/stuclass/:sid', url: 'StuClass'},
    {path:'/jiabanwanfu', url: 'JiabanWanfu'},
    {path:'/price', url: 'PriceView'},
    {path:'/history_salary', url: 'SalaryHistory'},
    {path:'/last_salary', url: 'SalaryLast'},
    {path:'/queryStu', url: 'QueryStu'},
    {path:'/authorityManage', url: 'AuthorityManage'},
]

export default routerPC
import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";
import moment from "moment";
import mobile from "./mobile";
import pc from "./pc";
import { isMOBILE,TeaClassList,zhuliTeaClassList,postJson,EmtryCla } from "@/xutil/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cTime: moment().startOf(isMOBILE?"day":"isoWeek").format(), // 被选中的时间
    user: JSON.parse(sessionStorage.getItem("user")), //用户信息
    teaList: JSON.parse(sessionStorage.getItem("teaList"))||[], //教师列表
    winHeight: document.documentElement.clientHeight,
    winWidth: document.documentElement.clientWidth,
    gradeArr: JSON.parse(sessionStorage.getItem("gradeArr")) || [], //年级数组
    classArr: JSON.parse(sessionStorage.getItem("classArr")) || [], //班课年级数组
    timeArr: [
      "08点-10点",
      "10点-12点",
      "13点-15点",
      "15点-17点",
      "17点-19点",
      "19点-21点",
    ],
    subjectArr: [
      "语文",
      "数学",
      "英语",
      "科学",
      "历史",
      "物理",
      "化学",
      "生物",
      "地理",
      "政治",
      "技术",
    ],
    socket:null,
    messageList:[], // 消息列表
    updateTeaTable:1, // 变化时更新课表
  },
  getters: {
    // 0排课权限、1薪资管理、2核对课时、3加班晚辅、4数据图表、5修改学生信息、6教师列表、7发课表、8添加学生、9添加教师、J、添加班课、Q班课列表、K续费退费
    // 是否有排课权限
    isRoot(state) {
      return (
        state.user &&
        state.user.authority &&
        state.user.authority.indexOf("0") >= 0
      );
    },
    // 是否有最高boss权限
    isBoss(state) {
      return (
        state.user &&
        state.user.authority &&
        state.user.authority.indexOf("4") >= 0
      );
    },
    // 在职的上课教师列表
    teaList1(state) {
      if (state.teaList) {
        return state.teaList
          .filter((tea) => tea.state > -1 && tea.subject.length > 0) //挑出在职教师
          .sort((a, b) => a.subGrade - b.subGrade) //按学科排序
          .sort((a, b) => b.state - a.state); // 按状态排序
      } else {
        return [];
      }
    },
    // 课程顾问数组
    monitorArr(state) {
      return state.teaList.filter((tea) => tea.state >= 0 && tea.job === 1)
    },
    classArrSession(state) {
      return state.classArr.map((item, index) => {
        return { session: index + 1, grade: item };
      });
    },
    gradeArrSession(state) {
      return state.gradeArr.map((item, index) => {
        let date = new Date();
        let session = date.getFullYear() - index + state.gradeArr.length;
        if (date.getMonth() < 8) session--;
        return { session, grade: item };
      });
    },
    // 是否请求全部教师列表 0 全部
    isAllTea(state){
      let tid = state.user.id
      if(
        state.user.authority.indexOf('0')>=0 ||
        state.user.authority.indexOf('2')>=0 ||
        state.user.job===1
      ){
        tid = 0
      }
      return tid;
    }
  },
  mutations: {
    // 登录 缓存
    setUser(state, user) {
      state.gradeArr = user.gradeArr;
      sessionStorage.setItem("gradeArr", JSON.stringify(user.gradeArr));
      state.classArr = user.classArr;
      sessionStorage.setItem("classArr", JSON.stringify(user.classArr));
      delete user.gradeArr;
      delete user.classArr;
      if (!user.authority) {
        user.authority = [];
      }
      state.user = user;
      sessionStorage.setItem("user", JSON.stringify(user));
    },
    // 退出清除缓存
    logout(state) {
      if(state.socket){
        state.socket.close(3001,state.user.id)
        state.socket=null;
      }
      state.messageList = [];
      state.gradeArr = [];
      state.classArr = [];
      state.user = null;
      state.cTime = moment().startOf(isMOBILE?"day":"isoWeek").format();
      state.teaList = [];
      localStorage.clear();
      sessionStorage.clear();
      state.pc.teaClassData = null;
      state.mobile.stuList = [];
      state.mobile.page = 0;
      state.mobile.scrollTop = 0;
      state.mobile.curStu = {};
      state.mobile.curTea = {};
      router.push("/");
    },
    // 设置当前选中的时间 缓存
    setCTime(state, cTime) {
      state.cTime = moment(cTime).format();
    },
    // 设置教师列表
    setTeaList(state, teaList) {
      state.teaList = teaList;
      sessionStorage.setItem("teaList", JSON.stringify(teaList));
    },
    setSocket(state,socket){
      state.socket=socket;
    },
    // 设置消息列表 获取更多消息
    setMessageList(state, messageList) {
      state.messageList = messageList.concat(state.messageList);
    },
    // 设置消息列表 有新消息
    setNewMessage(state, messageList) {
      state.messageList.push(messageList);
    },
    // 更新一下课表
    setUpdateTeaTable(state){
      state.updateTeaTable++;
    }
  },
  actions: {
    // 登录
    login(context, params) {
      return new Promise((resolve, reject) => {
        params = postJson(params);
        axios.post("/www1/login", params).then((res) => {
          if (res.data) {
            if (res.data.state == -1) {
              // 离职的不能登录
              resolve("您已离职");
            } else {
              // 登录成功后
              context.commit("setUser", res.data); // 设置store中的user
              context.dispatch("getTeaList", context.getters.isAllTea); // 请求教师列表
              if(res.data.authority.indexOf('0')===-1){
                context.dispatch('getMessageList'); // 请求消息列表
              }
              context.dispatch('conWebSocket'); // 链接服务器
              resolve(false);
            }
          } else {
            resolve("用户名或密码错误！");
          }
        }).catch(error=>{
          this._vm.$message.error('网络错误，请联系管理员')
        })
      });
    },
    // 请求消息列表
    getMessageList(context,obj){
      return new Promise((resolve, reject) => {
        let readState = 0;
        let page = 0;
        if(obj){
          readState = obj.readState;
          page = obj.page;
        }
        axios.get(`/www6/getMessageList/${context.state.user.id}&${readState}&${page}`).then((res) => {
          if(res.data!==0){
            context.commit("setMessageList", res.data)
            resolve(false)
          }else{
            resolve(true)
          }
        });
      })
    },
    // 校验原密码是否正确
    checkOldPwd(context, val) {
      return new Promise((resolve, reject) => {
        let params = {
          id: context.state.user.id,
          pwd: val,
        };
        params = postJson(params);
        axios
          .post("/www1/checkOldPwd", params)
          .then((res) => resolve(res.data === 1));
      });
    },
    // 修改用户密码和头像
    updatePwd(context, params) {
      return new Promise((resolve, reject) => {
        params.id = context.state.user.id;
        params = postJson(params);
        axios.put("/www1/updatePwd", params).then((res) => {
          if (res.data == 1) {
            context.commit("logout");
          }
          resolve(res.data);
        });
      });
    },
    // 请求教师列表
    getTeaList(context, tid) {
      axios.get("/www1/getTeaList/" + tid).then((res) => {
        context.commit("setTeaList", res.data);
      });
    },
    //请求一段时间内的学生课表  /getPaikeList/:start&:end&:tid  排课中使用
    getPaikeList(context, obj) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/www2/getPaikeList/${obj.start}&${obj.end}&${obj.tid}`)
          .then((res) => {
            if (obj.i === -1) {
              //paikeData存在时 替换数据
              //先清空原来的数据
              obj.teaClassData.forEach((rowData) => {
                rowData.thisTeaClassData = res.data.filter(
                  (cla) => cla.teaId == rowData.tea.tid
                );
                rowData.classData = rowData.classData.map(
                  (cla) => new EmtryCla()
                );
              });
            } else {
              //生成每一行数据
              context.state.timeArr.forEach((timeStr, index) => {
                let rowData = {
                  tea: context.getters.teaList1[obj.i],
                  thisTeaClassData: res.data,
                  classData: [],
                  timeStr,
                  time: timeStr.slice(0, 2),
                  ap: index < 2 ? "上午" : index < 4 ? "下午" : "晚上",
                };
                obj.dateArr.forEach((date) => {
                  rowData.classData.push(new EmtryCla());
                });
                obj.teaClassData.push(rowData);
              });
            }
            //循环此教师的课并放入正确的位置
            res.data.forEach((cla) => {
              let i = obj.dateArr.findIndex(item=>{
                return moment(item).isSame(cla.datetime, 'day');
              });
              let teaIndex = context.getters.teaList1.findIndex(
                (tea) => tea.tid == cla.teaId
              );
              if (i >= 0 && teaIndex >= 0) {
                cla.gradeArr = context.state.gradeArr;
                switch (moment(cla.datetime).format("HH")) {
                  case "08":
                    obj.teaClassData[teaIndex * 6 + 0].classData[i] =
                      new EmtryCla(cla);
                    break;
                  case "10":
                    obj.teaClassData[teaIndex * 6 + 1].classData[i] =
                      new EmtryCla(cla);
                    break;
                  case "13":
                    obj.teaClassData[teaIndex * 6 + 2].classData[i] =
                      new EmtryCla(cla);
                    break;
                  case "15":
                    obj.teaClassData[teaIndex * 6 + 3].classData[i] =
                      new EmtryCla(cla);
                    break;
                  case "17":
                    obj.teaClassData[teaIndex * 6 + 4].classData[i] =
                      new EmtryCla(cla);
                    break;
                  case "19":
                    obj.teaClassData[teaIndex * 6 + 5].classData[i] =
                      new EmtryCla(cla);
                  default:
                    break;
                }
              }
            });
            //处理完毕后
            resolve(res.data);
          });
      });
    },
    // 条件筛选学生列表
    getFiltertuList(context,params){
      return new Promise((resolve, reject) => {
        params = postJson(params);
        axios.post(`/www1/getFiltertuList`,params)
        .then(res => {
          resolve(res.data)
        })
      })
    },
    // 条件筛选班课列表
    getFilterBankeList(context,params){
      return new Promise((resolve, reject) => {
        params = postJson(params);
        axios.post(`/www7/getFilterBankeList`,params)
        .then(res => {
          resolve(res.data)
        })
      })
    },
    //分页请求学生列表/getPageStuList/:monitor&:page
    getPagetuList(context, page) {
      let { user } = context.state;
      let monitor = context.getters.isRoot || context.getters.isBoss? 0 : user.id;
      return new Promise((resolve, reject) => {
        axios.get(`/www1/getPageStuList/${monitor}&${++page}`).then((res) => {
          resolve(res.data);
        });
      });
    },
    //根据sid修改学生信息的接口  /updateStu
    updateStu(context, params) {
      params = postJson(params);
      return new Promise((resolve, reject) => {
        axios.post("/www1/updateStu", params).then((res) => {
          resolve(res.data);
        });
      });
    },
    //请求学生的课时记录
    getStuClassList(context, sid) {
      return new Promise((resolve, reject) => {
        axios.get(`/www1/getStuClassList/${sid}`).then((res) => {
          if (res.data != 0) {
            res.data.forEach((element) => {
              element.dateLocalStr = moment(element.datetime).format("LL");
            });
            resolve(res.data);
          } else {
            resolve(null);
          }
        });
      });
    },
    //获得续费退费记录
    getXutuiRecord(context, sid) {
      return new Promise((resolve, reject) => {
        axios.get("/www1/getXutuiRecord/" + sid).then((res) => {
          if (res.data != 0) resolve(res.data);
        });
      });
    },
    //根据sid修改学生信息的接口  /updateStu
    updateStu(context, params) {
      params = postJson(params);
      return new Promise((resolve, reject) => {
        axios.post("/www1/updateStu", params).then((res) => {
          resolve(res.data);
        });
      });
    },
    // 批量修改学生
    updateStuBranch(context, params) {
      params = postJson(params);
      return new Promise((resolve, reject) => {
        axios.post("/www1/updateStuBranch", params).then((res) => {
          resolve(res.data);
        });
      });
    },
    //根据tid修改教师信息的接口
    updateTea(context, params) {
      params = postJson(params);
      return new Promise((resolve, reject) => {
        axios.post("/www1/updateTea", params).then((res) => {
          resolve(res.data);
        });
      });
    },
    // 添加学生
    addStudent(context, params) {
      return new Promise((resolve, reject) => {
        params = postJson(params);
        axios.post("/www1/addStudent", params).then((res) => {
          resolve(res.data);
        });
      });
    },
    // 添加教师
    addTeacher(context, params) {
      return new Promise((resolve, reject) => {
        params = postJson(params);
        axios.post("/www1/addTeacher", params).then((res) => {
          resolve(res.data);
        });
      });
    },
    //请求每个老师的课时记录
    getTeaStuMonthClassLength(context, tid) {
      return new Promise((resolve, reject) => {
        let url = `/www1/getTeaClassList/${tid}`;
        axios.get(url).then((res) => {
          if (res.data != 0) {
            resolve(res.data);
          } else {
            resolve(null);
          }
        });
      });
    },
    // 检查教师姓名是否重复
    checkTname(context, tname) {
      return new Promise((resolve, reject) => {
        axios.get(`/www1/checkTname/${tname}`).then((res) => {
          resolve(res.data);
        });
      });
    },
    // 检查学生姓名是否重复
    checkSname(context, sid) {
      return new Promise((resolve, reject) => {
        axios.get(`/www1/checkSname/${sid}`).then((res) => {
          resolve(res.data);
        });
      });
    },
    // 检查班课姓名是否重复
    checkBname(context, sid) {
      return new Promise((resolve, reject) => {
        axios.get(`/www7/checkBname/${sid}`).then((res) => {
          resolve(res.data);
        });
      });
    },
    //请求一段时间内的学生课表  /getClassList/:start&:end&:tid  发课表和核对课时时使用
    getClassList(context,obj){
      return new Promise((resolve,reject)=>{
        let tid = 0;
        if(!context.getters.isRoot&&!context.getters.isBoss&&context.state.user.job===1){
          tid=context.state.user.id;
        }
        axios.get(`/www2/getClassList/${obj.start}&${obj.end}&${tid}`)
        .then(res => {
          if(obj.type=='heduikeshi'){
            resolve(zhuliTeaClassList(res.data))//发送到核对课时中
          }else{
            resolve(res.data)//发送到发课表中
          }
        })
      })
    },
    // 获取加班晚辅数据
    getJiabanWanfu(context,obj){
      return new Promise((resolve, reject) => {
        axios.get("/www4/getJiabanWanfu").then(res=>resolve(res.data))
      })
    },
    // 链接服务器
    conWebSocket(context){
      let {user,cTime,messageList}=context.state;
      if(window.WebSocket){
        let socket = new WebSocket(`ws${this._vm.baseURL}/www6/socketMsg/${context.state.user.id}`);
        context.commit('setSocket',socket)
        socket.onopen=(e)=>{
        }
        socket.onmessage=(e)=>{
          let msg=JSON.parse(e.data)
          console.log("收到了服务器的消息", msg);
          if(msg.type==2){ // 强制下线
            context.commit('logout');
            this._vm.$message({
              showClose: true,
              offset:70,
              center:true,
              duration:5000,
              message: '您已在其他设备登录, 此设备强制下线',
              type: 'warning'
            });
          }else if(msg.type===3){
            // 更新一下课表
            if(!context.getters.isRoot){
              context.commit('setUpdateTeaTable');
            }
            // 插入消息列表
            if(msg.msgText){
              context.commit('setNewMessage',msg)
            }
          }
        }

        socket.onclose=(e)=>{
          console.log('断开连接');
        }
        socket.onerror=()=>{
          console.log('连接出错');
        }
      }else{
        console.log("不支持socket");
      }
    },
    // 获得当前班课的学生列表
    getBankeStuList(context,sid){
      return new Promise((resolve, reject) => {
        axios.get("/www7/getBankeStuList/" + sid).then(res=>{
          res.data.forEach(item=>{
            item.afl = JSON.parse(item.afl)
          })
          resolve(res.data)
        })
      })
    },
    // 给班课加学生添加学生
    addBankeStudent(context,obj){
      return new Promise((resolve, reject) => {
        obj.list = JSON.stringify(obj.list);
        let params = postJson(obj);
        axios.post("/www7/addBankeStudent/",params).then(res=>resolve(res.data))
      })
    },
    // 给班课删除学生
    deleteBankeStudent(context,list){
      return new Promise((resolve, reject) => {
        list = JSON.stringify(list);
        let params = postJson({list});
        axios.post("/www7/deleteBankeStudent/",params).then(res=>resolve(res.data))
      })
    },
    // 批量彻底删除学生
    cediDeleteStu(context,list){
      return new Promise((resolve, reject) => {
        list = JSON.stringify(list);
        let params = postJson({list});
        axios.post("/www1/cediDeleteStu",params).then(res=>resolve(res.data))
      })
    },
  },
  modules: {
    mobile,
    pc
  },
});
